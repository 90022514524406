<template>
  <div
    class="numeric-input-wrapper enext-input form__numericInput"
    :class="{ 'enext-input--validable': rules && rules.length, invalid: errorMessageComputed }"
  >
    <div class="numeric__input">
      <span
        class="apply-changes-button"
        @click="triggerEvent(valChanged)"
        v-show="disableLiveUpdate && valChanged"
      >
        <NavigationCheck />
      </span>
      <div
        :class="{
          'numeric-input-container': true,
          'enext-input--new-style-with-spinner': newStyleWithSpinner,
          'input-field-error-border': errorMessageWithIconAndBorder && errorMessageComputed
        }"
      >
        <NumericTextBox
          ref="numericInput"
          class="input"
          :class="{'enext-input--new-style new-style': newStyle}"
          input-class="data-hj-allow"
          :spinners="showSpinners"
          v-bind="{
            name: uuid + '_numericinput',
            id: uuid + '_numericinput',
            ref: uuid + '_numericinput',
            ...customAttrs,
          }"
          v-on="{ change: inputEvent, focusout: focusoutEvent }"
          @blur="triggerEvent(!valChanged && !disableLiveUpdate)"
          @keydown.enter="
            () => {
              this.$emit('keydown-enter');
              triggerEvent(valChanged);
            }
          "
          @keydown.tab="triggerEvent(valChanged)"
        />
        <AlertErrorIcon
          v-if="errorMessageWithIconAndBorder && errorMessageComputed"
          class="numeric-input-field-error-icon"
        />
      </div>
    </div>
    <ErrorMessage
      :error-message="errorMessageComputed"
      :inline-variant="errorMessageWithIconAndBorder"
      class="enext-input__errormessage"
    />
  </div>
</template>

<script>
import {NumericTextBox} from '@progress/kendo-vue-inputs'
import inputValidationMixin from '~/mixins/input_validation'
import ErrorMessage from '~/components/input-components/ErrorMessage'
import NavigationCheck from '~/assets/icons/navigation_check.svg?component'
import AlertErrorIcon from 'assets/icons/alert_error.svg'

export default {
  components: {
    AlertErrorIcon,
    ErrorMessage,
    NumericTextBox,
    NavigationCheck
  },
  mixins: [inputValidationMixin],
  data() {
    return {
      valChanged: false,
      previousValue: null
    }
  },
  props: {
    disableLiveUpdate: {
      type: Boolean,
      default: () => false,
      required: false
    },
    manualErrors: {
      type: String,
      default: () => null
    },
    newStyle: {
      type: Boolean,
      default: () => false
    },
    newStyleWithSpinner: {
      type: Boolean,
      default: () => false
    },
    errorMessageWithIconAndBorder: {
      type: Boolean,
      default: () => false
    },
    showSpinners: {
      type: Boolean,
      default: () => false
    },
    validateInputOnChange: {
      type: Boolean,
      default: () => false
    },
    forceValidate: {
      type: Number,
      default: () => null
    }
  },
  inheritAttrs: true,
  mounted() {
    this.previousValue = this.$refs[this.uuid + '_numericinput']?.modelValue
  },
  methods: {
    inputEvent(event) {
      if (event.event.type === 'wheel') {
        event.target.value = this.previousValue
        this.$emit('update:modelValue', this.previousValue)
      }
      if (event.event.pointerType === 'mouse' || event.event.code === 'ArrowUp' || event.event.code === 'ArrowDown') {
        this.$emit('spin', event.target.value)
      }
      this.$emit('input', event.target.value)
      this.valChanged = this.previousValue !== event.target.value
      this.previousValue = this.disableLiveUpdate ? this.previousValue : event.target.value
      if (this.validateInputOnChange) {
        this.validateInput(event.target.value)
      }
      if (event.event.type === 'wheel') {
        this.blur()
      }
    },
    triggerEvent(changed) {
      if (changed) {
        this.$emit('focusOut')
        this.previousValue = this.$refs[this.uuid + '_numericinput']?.modelValue
        this.valChanged = false
      }
    },
    focusoutEvent(event) {
      if (this.validateInputOnChange) {
        this.validateInput(event.target.value)
      }
    },
    focus() {
      if (this.$refs.numericInput && this.$refs.numericInput.focus) {
        this.$refs.numericInput.focus()
      } else {
        const inputElement = this.$el.querySelector('.input input')
        if (inputElement) {
          inputElement.focus()
        }
      }
    },
    blur() {
      if (this.$refs.numericInput && this.$refs.numericInput.focus) {
        this.$refs.numericInput.blur()
      } else {
        const inputElement = this.$el.querySelector('.input input')
        if (inputElement) {
          inputElement.blur()
        }
      }
    },
    async validateInput(inputValue) {
      if (inputValue !== '' && inputValue !== null) {
        if (typeof inputValue === 'string') {
          inputValue = inputValue.replaceAll(',', '')
        }
        inputValue = parseFloat(inputValue)
      }
      const isValid = await this.validate(inputValue)
      this.$emit('validationResult', isValid)
      if (!isValid) {
        if (typeof this.fallbackValue === 'number') {
          this.$emit('update:modelValue', this.fallbackValue)
          this.fallbackValue = null
        }
      }
    },
    async validateField() {
      const inputValue = this.$refs[this.uuid + '_numericinput']?.modelValue
      const isValid = await this.validate(inputValue)
      return isValid
    }
  },
  computed: {
    customAttrs() {
      let attrs = {...this.$attrs, class: ''}
      return attrs
    },
    errorMessageComputed() {
      return this.manualErrors || this.errorMessage
    }
  },
  watch: {
    forceValidate() {
      const inputValue = this.$refs[`${this.uuid}_numericinput`]?.modelValue
      if (inputValue) {
        this.validateInput(inputValue)
      }
    }
  }
}
</script>

<style lang="scss">
.numeric-input-wrapper {
  width: 100%;

  .input {
    width: 100%;
  }

  .enext-input--new-style.new-style {
    border: .0625rem solid $color--input__border;

    .k-input-inner {
      border: none;
    }

    .k-input-spinner.k-spin-button {
      width: 2rem;
      display: none;

      .k-button {
        background-color: $color__page--background;

        .k-icon {
          color: $color__fst_gray__100;
        }
      }
    }
  }

  .enext-input--new-style-with-spinner {
    border: .0625rem solid $color--input__border;

    ::placeholder {
      opacity: 0.5;
    }

    .k-input-inner {
      border: none;
    }

    .k-input-spinner.k-spin-button {
      width: 2rem;
      display: flex;

      .k-button {
        background-color: $color__page--background;

        .k-icon {
          color: $color__fst_gray__100;
        }
      }
    }
  }

  &:focus-within,
  &:focus {
    .enext-input--new-style.new-style {
      .k-input-spinner.k-spin-button {
        display: flex;
      }
    }
  }

  .numeric-input-container {
    display: flex;
    align-items: center;
    position: relative;

    .numeric-input-field-error-icon {
      position: absolute;
      margin-left: 7.2rem;
      font-size: 1.5rem;
      color: $color--input__border--error;
    }
  }

  .input-field-error-border {
    border: thin solid $color--input__border--error !important;
  }
}
</style>
